// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyDV0WqpPqn-Zu1xhiSXSs2SXrvgLadewOQ",
    authDomain: "juuride.firebaseapp.com",
    // firebase path - dev
    // databaseURL:"https://juuride-tracking.firebaseio.com",
    // firebase path - prod
    databaseURL:"https://juuride-prod-tracking.firebaseio.com/",
    projectId: "juuride",
    storageBucket: "juuride.appspot.com",
    messagingSenderId: "620511342782",
    appId: "1:620511342782:web:149ecf4fb684c964e2eeef",
    measurementId: "G-DH14NLLG2X"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
