import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './components/home/home.component';

const routes: Routes = [
  { 
    path: '', 
    redirectTo: '/redirect', 
    pathMatch: 'full'
  },
  {
    path: 'redirect',
    component: HomeComponent
  },
  {
    path: 'ride',
    loadChildren: () => import('./components/rides/rides.module').then(m => m.RidesModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
